<template>
  <!-- 新闻管理 -->
  <div class="page">
    <!-- 顶部搜索框 新增按钮 -->
    <div class="">
      <el-form :model="ruleForm" label-width="80px" ref="ruleForm">
        <el-row>
          <el-col :span="6">
            <el-form-item label="新闻标题">
              <el-input
                v-model="ruleForm.title"
                placeholder="请输入标题"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="新闻类型">
              <el-select v-model="ruleForm.type" placeholder="请选择">
                <el-option
                  v-for="item in data"
                  :key="item.id"
                  :value="item.id"
                  :label="item.typeName"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="6">
            <div class="flex">
              <el-button size="small" @click="resetForm('ruleForm')"
                >重置</el-button
              >
              <el-button size="small" type="primary" @click="doFilter"
                >查询</el-button
              >
            </div>
          </el-col>
          <el-col :span="2" :offset="4">
            <el-button type="danger" @click="newPage">新增</el-button>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <!-- 中间表格区 -->
    <div>
      <el-table :data="data" border style="width: 100%">
        <el-table-column
          label="创建时间"
          prop="createTime"
          width="180"
        ></el-table-column>
        <el-table-column
          label="新闻标题"
          prop="title"
          width="300"
        ></el-table-column>
        <el-table-column
          label="内容"
          prop="content"
          width="300"
        ></el-table-column>
        <el-table-column label="状态" width="100">
          <template slot-scope="scope">
            {{ scope.row.store ? "审核通过" : "未审核" }}
          </template>
        </el-table-column>
        <el-table-column
          label="文章类型"
          prop="typeName"
          width="80"
          align="center"
        ></el-table-column>
        <el-table-column
          label="排序"
          prop="sort"
          width="80"
          align="center"
        ></el-table-column>
        <el-table-column
          label="操作人"
          prop="operationOf"
          width="80"
        ></el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <div class="operation">
              <div @click="chaKan(scope.$index, scope.row.id)">查看</div>
              <div @click="xiuGai(scope.$index, scope.row.id)">修改</div>
              <div v-if="scope.row.store">审核</div>
              <div @click="handleDelete(scope.$index, scope.row.id)">删除</div>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 底部分页 -->
    <div class="pagination">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[10, 15, 20, 30]"
        :page-size="pageNumber"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      ruleForm: {
        title: "",
        type: "",
      },
      options: [],
      data: [],
      total: null,
      currentPage: 1,
      pageNumber: null,
    };
  },
  created() {
    // 调用查看所有资讯接口
    this.onnesPage();
  },
  methods: {
    // 跳转页面
    newPage() {
      this.$router.push("addNews");
    },
    // 查看页面
    chaKan(idnex, id) {
      this.$router.push({ path: "addNewssss", query: { id } });
    },
    // 修改页面
    xiuGai(idnex, id) {
      this.$router.push({ path: "addNewddd", query: { id } });
    },
    //查看所有资讯
    onnesPage() {
      this.axios
        .get("/admin/consult/findAll", {})
        .then((res) => {
          this.data = res.data.data.records;
          this.total = res.data.data.total;
          this.currentPage = res.data.data.current;
          this.pageNumber = res.data.data.size;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 重置
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.ruleForm = {
        title: "",
        type: "",
      };
      this.onnesPage();
    },
    // 查询按钮
    doFilter() {
      console.log(this.ruleForm);
      this.axios
        .get("/admin/consult/findAll", {
          title: this.ruleForm.title,
          typeId: this.ruleForm.type,
        })
        .then((res) => {
          if (res.data.code == 200) {
            this.data = res.data.data.records;
            this.total = res.data.data.total;
            this.currentPage = res.data.data.current;
            this.pageNumber = res.data.data.size;
          } else {
            this.$message({
              message: "查无结果！",
              type: "warning",
            });
          }
        });
    },
    // 删除
    handleDelete(index, id) {
      console.log(index, id);
      this.$confirm("此操作将永久删除该文件, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.axios
            .delete(`/admin/consult/delConsult?ids=${id}`, {})
            .then((res) => {
              console.log(res);
              if (res.data.data) {
                this.$message({
                  type: "success",
                  message: "删除成功!",
                });
                this.data.splice(index, 1);
              }
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    // 分页
    handleSizeChange(val) {
      this.pageNumber = val;
      this.axios
        .get("/admin/consult/findAll", {
          pageNumber: this.currentPage,
          pageSize: this.pageNumber,
        })
        .then((res) => {
          this.data = res.data.data.records;
        });
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.axios
        .get("/admin/consult/findAll", {
          currentPage: this.currentPage,
          pageSize: this.pageNumber,
        })
        .then((res) => {
          this.data = res.data.data.records;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.flex {
  margin: 5px 0 0 10px;
}
.operation {
  display: flex;
  div {
    margin-left: 12px;
    font-size: 12px;
    color: #409eff;
  }
  div:hover {
    cursor: pointer;
  }
}
.pagination {
  padding: 60px 0 0 110px;
}
</style>
